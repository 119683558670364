import React from "react";
import { useHistory } from "react-router-dom";
import format from "date-fns/format";

const dtFmt = "MM/dd/yyyy";

/*
	rowKey
	appTitle
	appDescShort
	appDescLong
	appUrl
	imgUrl1
	imgUrl2
	imgUrl3
	imgUrl4
	tags
	createdDateUtc
	timestamp
	rawDataSource
	dataSource
	refreshSchedule
*/
export const AppBox = (props) => {
	const history = useHistory();

	const handleClick = () => {
		history.push(`details/${props.rowKey}`);
	}

	return (
		<div className="app-box" onClick={handleClick}>
			<div className="thumbnail">
				<img src={props.imgUrl1} aria-label={`Thumbnail for ${props.appTitle}`} />
				{/*<i className="pi pi-heart" />*/}
			</div>
			<div>
				<h2>{props.appTitle}</h2>
				<p>{props.appDescShort}</p>
				<div className="detail">
					<div>Updated: </div>
					{format(new Date(props.timestamp), dtFmt)}
				</div>
				<div className="detail">
					<div>Refresh: </div>
					{props.refreshSchedule}
				</div>
				{props.tags.map((tag, tagIdx) => (
					<div key={tagIdx} className="tag">
						{tag}
					</div>
				))}
			</div>
		</div>
	);
}