import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../UserContext";
import format from "date-fns/format";
import { TabView, TabPanel } from 'primereact/tabview';
import { useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';
const dtFmt = "MM/dd/yyyy";
const unloadedData = {
	createdDateUtc: "2000-01-01T12:00:00Z",
	timestamp: "2000-01-01T12:00:00Z",
	tags: []
}

export const AppDetails = () => {
	const [appData, setAppData] = useState(unloadedData);
	const [selectedImg, setSelectedImg] = useState("");

	let { rowKey } = useParams();
	const user = useContext(UserContext);
	const history = useHistory();
	useEffect(() => {
		user.apiCall(`appdetails/${rowKey}`)
			.then((res) => {
				setAppData(res.data);
				console.log(res.data)
				setSelectedImg(res.data.imgUrl1);
			});

		// Check layout-main and erase padding
		const holder = document.getElementsByClassName("layout-main");
		holder[0].classList.add("erase-padding");

		return () => {
			const holder = document.getElementsByClassName("layout-main");
			holder[0].classList.remove("erase-padding");
		}
	},[])

	return (
		<div id="AppDetails">
			<div className="img-section">
				<div className="img-list">
					<img src={appData?.imgUrl1}
						onClick={() => setSelectedImg(appData?.imgUrl1)}
						className={selectedImg === appData?.imgUrl1 ? " selected" : ""}
					/>
					{appData?.imgUrl2 !== null && appData?.imgUrl2 !== undefined && (
						<img src={appData.imgUrl2}
							onClick={() => setSelectedImg(appData.imgUrl2)}
							className={selectedImg === appData.imgUrl2 ? " selected" : ""}
						/>
					)}
					{appData?.imgUrl3 !== null && appData?.imgUrl3 !== undefined && (
						<img src={appData.imgUrl3}
							onClick={() => setSelectedImg(appData.imgUrl3)}
							className={selectedImg === appData.imgUrl3 ? " selected" : ""}
						/>
					)}
					{appData?.imgUrl4 !== null && appData?.imgUrl4 !== undefined && (
						<img src={appData.imgUrl4}
							onClick={() => setSelectedImg(appData.imgUrl4)}
							className={selectedImg === appData?.imgUrl4 ? " selected" : ""}
						/>
					)}
				</div>
				<div className="full-img">
					<img src={selectedImg} />
				</div>
			</div>
			<div className="detail-section">
				<h1>{appData?.appTitle}</h1>
				<p style={{fontSize:'18px',fontWeight:'500'}}>{appData?.appDescShort}</p>
				<div className="tags">
					{appData?.tags.map((tag, tagIdx) => (
						<div className={'tag'+tagIdx} key={tagIdx}>
							{tag}
						</div>
					))}
				</div>
				<div className="app-metadata">
					<div>
						<div className="mdTitle">Entry Last Updated:</div>
						<div>{new Date(appData.timestamp).toLocaleDateString('en-US')}</div>
					</div>
					<div>
						<div className="mdTitle">Original Data:</div>
						<div>{appData.rawDataSource}</div>
					</div>
					<div>
						<div className="mdTitle">Refresh Schedule:</div>
						<div>{appData.refreshSchedule}</div>
					</div>
					<div>
						<div className="mdTitle">Data Source:</div>
						<div>{appData.dataSource}</div>
					</div>
					<div/>
				</div>
				<div className="app-description">
					{appData.appDescLong}
				</div>
				<Button style={{fontWeight:'600',fontSize:'16px', padding:'10px',paddingLeft:'20px',paddingRight:'20px',backgroundColor:'#477FDF'}}
				label={appData?.appUrl?.includes('powerbi') ? "Go to PowerBI Report" : "Go to App"} 
				onClick={() => {
					window.open(appData.appUrl, '_blank').focus();
				}}/>
				<Button style={{fontWeight:'600',fontSize:'16px', padding:'10px',paddingLeft:'20px',paddingRight:'20px',backgroundColor:'#477FDF', marginLeft: "30px"}}
				label={"Go back"} 
				onClick={() => {
					history.push('/');
				}}/>
			</div>
		</div>
	)
}