import React, { useState, useContext } from 'react';
import classNames from 'classnames';
import { Route } from 'react-router-dom';
import { UserContext } from './UserContext';

import AppTopBar from './AppTopbar';
import AppFooter from './AppFooter';
import AppMenu from './AppMenu';

import { Dashboard } from './pages/Dashboard';
import { AppDetails } from './pages/AppDetails';
//import { UnderConstruction } from './pages/UnderConstruction';

import PrimeReact from 'primereact/api';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';

const menu = [
	{
		label: '',
		icon: 'pi pi-fw pi-star-o',
		items: [
			/*{ label: 'New Page', icon: 'pi pi-fw pi-th-large', to: '/' },*/
		]
	}
];

const routers = [
	{ path: "/", component: Dashboard, exact: true },
	{ path: "/details/:rowKey", component: AppDetails, exact: true }
];

const App = () => {
	const [menuActive, setMenuActive] = useState(false);
	const [menuMode, setMenuMode] = useState('static');
	const [overlayMenuActive, setOverlayMenuActive] = useState(false);
	const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(true);
	const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
	const [configActive, setConfigActive] = useState(false);

	const user = useContext(UserContext);

	let menuClick = false;
	let configClick = false;

	const onDocumentClick = () => {
		if (!menuClick) {
			if (isHorizontal() || isSlim()) {
				setMenuActive(false);
			}

			hideOverlayMenu();
		}

		if (configActive && !configClick) {
			setConfigActive(false);
		}

		menuClick = false;
		configClick = false;
	};

	const onMenuClick = () => {
		menuClick = true;
	};

	const onMenuitemClick = (event) => {
		if (!event.item.items) {
			hideOverlayMenu();
		}

		if (!event.item.items && (isSlim() || isHorizontal())) {
			setMenuActive(false);
		}
	};

	const onRootMenuitemClick = () => {
		setMenuActive(prevMenuActive => !prevMenuActive);
	};

	const onMenuButtonClick = (event) => {
		menuClick = true;

		if (menuMode === 'overlay') {
			setOverlayMenuActive(prevOverlayMenuActive => !prevOverlayMenuActive);
		}
		else {
			if (isDesktop())
				setStaticMenuDesktopInactive(prevStaticMenuDesktopInactive => !prevStaticMenuDesktopInactive);
			else
				setStaticMenuMobileActive(prevStaticMenuMobileActive => !prevStaticMenuMobileActive);
		}

		event.preventDefault();
	};

	const onTopbarMenuButtonClick = (event) => {
		hideOverlayMenu();
		event.preventDefault();
	};

	const hideOverlayMenu = () => {
		setOverlayMenuActive(false);
		setStaticMenuMobileActive(false);
	};

	const isDesktop = () => {
		return window.innerWidth > 1024;
	};

	const isHorizontal = () => {
		return menuMode === 'horizontal';
	};

	const isSlim = () => {
		return menuMode === 'slim';
	};

	const isMenuVisible = () => {
		if (isDesktop()) {
			if (menuMode === 'static')
				return !staticMenuDesktopInactive;
			else if (menuMode === 'overlay')
				return overlayMenuActive;
			else
				return true;
		}
		else {
			if (staticMenuMobileActive)
				return true;
			else if (menuMode === 'overlay')
				return overlayMenuActive;
			else
				return true;
		}
	};

	let containerClassName = classNames('layout-wrapper', {
		'menu-layout-static': menuMode !== 'overlay',
		'menu-layout-overlay': menuMode === 'overlay',
		'layout-menu-overlay-active': overlayMenuActive,
		'menu-layout-slim': menuMode === 'slim',
		'menu-layout-horizontal': menuMode === 'horizontal',
		'layout-menu-static-inactive': staticMenuDesktopInactive,
		'layout-menu-static-active': staticMenuMobileActive,
		'p-input-filled': false,
		'p-ripple-disabled': true,
	});
	let menuClassName = classNames('layout-menu-container', { 'layout-menu-dark': false, 'layout-menu-container-inactive': !isMenuVisible() });

	return (
		<div className={containerClassName} onClick={onDocumentClick}>
			<div className="layout-wrapper-content">
				<AppTopBar activeUser={user}
					onMenuButtonClick={onMenuButtonClick}
					onTopbarMenuButtonClick={onTopbarMenuButtonClick}
				/>
				<div className={menuClassName} onClick={onMenuClick}>
					<div className="menu-scroll-content">
						<AppMenu model={menu}
							menuMode={menuMode}
							active={menuActive}
							onMenuitemClick={onMenuitemClick}
							onRootMenuitemClick={onRootMenuitemClick}
						/>
					</div>
				</div>
				<div className="layout-main">
					{
						routers.map((router, index) => {
							let routeProps = {};
							if(router.exact) {
								routeProps.exact = true;
							}

							return (
								<Route key={`router${index}`}
									path={router.path}
									component={router.component}
									{...routeProps}
								/>
							)
						})
					}
				</div>

				<div className="layout-mask modal-in"></div>
				<AppFooter />
			</div>
		</div>
	);
}

export default App;
