import React, { useEffect } from 'react';
import { Route, useLocation, withRouter } from 'react-router-dom';
import App from './App';
import { UserContext, UserContextProvider } from "./UserContext";
import { Error } from './pages/Error';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";


const AppWrapper = () => {
	const { instance, accounts } = useMsal();

	let location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [location]);

		//This code handles when a user isnt logged in, to redirect to the login page
	//this could live in the App component, or handled elsewhere
	//for rolodex, we need the token to get info from the APIs before it gets to the app so i put it here
	useEffect(() => {
		const handleAuth = async () => {
			try {
				// Wait for the redirect promise to resolve
				//this needs to happen, because the accounts are null until this is done
				//so it goes into an endless redirect loop
				await instance.handleRedirectPromise();

				// If no accounts are found, initiate login redirect
				if (accounts.length === 0) {
					await instance.loginRedirect({
						scopes: ['user.read'],
					});
				}
			} catch (error) {
				console.error('Authentication error:', error);
			}
		};

		handleAuth();
	}, [accounts, instance]);

	switch (location.pathname) {
		case '/login':
			return null
		case '/error':
			return <Route path="/error" component={Error} />
		default:
			return <><UnauthenticatedTemplate>No Users Logged In</UnauthenticatedTemplate><AuthenticatedTemplate><UserContextProvider><App /></UserContextProvider></AuthenticatedTemplate></>;
	}
}

export default withRouter(AppWrapper);
